import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { renderHTMLString } from '../../../helpers';
import gtm from '../../../utils/initialGTM';

import SessionInfo from '../ModalSessionInfo/SessionInfo/SessionInfo';
import VirtualEventProposition from '../VirtualEventProposition/VirtualEventProposition';

import './ModalSessionInfo.scss';
import '../../shared/Modal/Modal.scss';

function PrevArrow(props) {
  const { t } = useTranslation();
  const { className, onClick, currentSlide, slideCount, slides } = props;
  const prevSlidePos = currentSlide === 0 ? slideCount - 1 : currentSlide - 1;
  const prevSlideText = slides[prevSlidePos].title;

  return (
    <div className={className} onClick={onClick}>
      <div className="arrow-prev">{t('event.agenda.previous')}</div>
      <div className="title">{renderHTMLString(prevSlideText)}</div>
    </div>
  );
}

function NextArrow(props) {
  const { t } = useTranslation();
  const { className, onClick, currentSlide, slideCount, slides } = props;
  const nextSlidePos = currentSlide !== slideCount - 1 ? currentSlide + 1 : 0;
  const nextSlideText = slides[nextSlidePos].title;

  return (
    <div className={className} onClick={onClick}>
      <div className="arrow-next">{t('event.agenda.next')}</div>
      <div className="title">{renderHTMLString(nextSlideText)}</div>
    </div>
  );
}

const types = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  slides: PropTypes.array,
};

PrevArrow.propTypes = types;
NextArrow.propTypes = types;

export class ModalSessionInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: this.props.sessionIndex,
      isWideModal: false,
    };
    this.carouselSettings = {
      currentSlide: this.props.sessionIndex,
      slideCount: this.props.sessions.length,
      dots: false,
      fade: true,
      infinite: false,
      speed: 0,
      prevArrow: <PrevArrow slides={props.sessions} />,
      nextArrow: <NextArrow slides={props.sessions} />,
      afterChange: (currentIndex) => {
        this.setState({ activeSlide: currentIndex });
        this.updateLocationHistory(currentIndex);
      },
    };
  }

  updateLocationHistory(currentIndex) {
    this.props.history.push(
      `${this.props.history.location.search}#${this.getSessionHash(
        currentIndex,
      )}`,
    );
  }

  getSessionHash(sessionIndex) {
    const session = this.props.sessions[sessionIndex];
    return session ? session.itemHash : '';
  }

  handleSessionIframeOpening = (isSessionIframeOpened) => {
    this.setState({
      isWideModal: isSessionIframeOpened,
    });
  };

  render() {
    const {
      currentDay,
      sessions,
      sessionIndex,
      id,
      isDateAndTimeDisplayed,
      closeModal = () => {},
      t,
      siteHeader: { data: { booking: isBooking } = {} } = {},
      isLearning,
    } = this.props;

    const currentSession =
      (id
        ? sessions.find((session) => session.id === id)
        : sessions[this.state.activeSlide]) || {};

    const modalContainerClass = classnames('c-modal__container', {
      'c-modal__container--wide': this.state.isWideModal,
    });

    gtm.pushSession(currentSession.title);

    return (
      <div className="c-modal c-modal-session-info">
        <div className="c-modal__wrapper c-modal-session-info__agenda-wrapper">
          <div
            className="c-modal__overlay"
            onClick={closeModal}
            role="presentation"
          />
          <div className={modalContainerClass}>
            <VirtualEventProposition
              session={currentSession}
              handleSessionIframeOpening={this.handleSessionIframeOpening}
              t={t}
            >
              {({
                virtualEventEnabled,
                virtualEventProposition,
                currentSlide,
              }) => (
                <SessionInfo
                  sessionIndex={sessionIndex}
                  closeModal={closeModal}
                  sessions={sessions}
                  carouselSettings={{
                    ...this.carouselSettings,
                    currentSlide,
                  }}
                  currentSession={currentSession}
                  isLearning={isLearning}
                  virtualEventEnabled={virtualEventEnabled}
                  virtualEventProposition={virtualEventProposition}
                  isDateAndTimeDisplayed={isDateAndTimeDisplayed}
                  currentDay={currentDay}
                  isBooking={isBooking}
                  t={t}
                />
              )}
            </VirtualEventProposition>
          </div>
        </div>
      </div>
    );
  }
}

ModalSessionInfo.propTypes = {
  sessionIndex: PropTypes.number,
  id: PropTypes.string,
  sessions: PropTypes.array,
  currentDay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isDateAndTimeDisplayed: PropTypes.bool,
  closeModal: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  siteHeader: PropTypes.object,
  t: PropTypes.func,
  isLearning: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(withRouter(ModalSessionInfo)),
);
